import React from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/img/arrow-down.svg';
import style from './BackButton.module.scss';

const BackButton = ({}) => {
    const navigate = useNavigate();

    return (
        <div className={style.button} onClick={() => navigate(-1)} draggable={false}>
        <img src={backIcon} alt="back button" />
      </div>
    );
};

export default BackButton;