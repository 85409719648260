//import Navbar from "../../components/Navbar/Navbar.jsx";
//import Dropdown from "../../components/Dropdown/Dropdown.jsx";
import style from './Dropdown.module.scss';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

function Dropdown({ children, title, height }) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <div className={style.dropdown}  >
      <div className={style.dropdown__title} onClick={() => setOpen(!open)}>
        <h1   >{title ? title : "Dropdown"}</h1>
        <img className={`${style.dropdown__minus} ${open ? style.dropdown__minus__open : style.dropdown__minus__close}`} src="/svg/minus.svg" alt="" />

        <img className={`${style.dropdown__plus} ${open ? style.dropdown__plus__open : style.dropdown__plus__close}`} src="/svg/plus.svg" alt="" />

      </div>
      
      <div className={`${style.dropdown__content} ${open ? style.dropdown__content__open : style.dropdown__content__close}`} style={{ height: open && height }}>
        {children}
      </div>

    </div>
  )
}

export default Dropdown;