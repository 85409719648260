import React from 'react';
import style from './ProductDetail.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useContext } from 'react';
import AuthContext from "../../utils/DataProvider.jsx";
import cartIcon from '../../assets/img/shopping-bag.svg';
import backIcon from '../../assets/img/arrow-down.svg';
import Slider from "react-slick";
import dataTalents from '../../assets/data/talents.json';
import { useState } from 'react';


const ProductDetail = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [product, setProduct] = useState(null);

    const { addItemCart, setOpenCartView, activeGlobalTalent, setActiveGlobalTalent } = useContext(AuthContext);
    const [origin, setOrigin] = useState(null);

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        const url = new URL(window.location.href);
        const pathname = url.pathname.replace(/\/$/, '');
        const lastPart = decodeURIComponent(pathname.split('/').pop());

        if (location.state) {
            setProduct(location.state);
        }
        else if (lastPart !== "journal") {
            let foundArticle = null;

            for (const item of dataTalents.products) {
                if (camelCaseToKebabCase(item.name) == lastPart) {
                    foundArticle = item;
                    break;
                }
            }


            if (foundArticle) {
                setProduct(foundArticle);
                setOrigin("external")
                return;
            }


        }
        else {
            navigate("/");
        }
    }, []);






    const handleAddToCart = (obj) => {
        addItemCart(obj)
        //SOLO SE LA REQUEST é ANDATA A BUON FINE
        setOpenCartView(true);

    }
    const clickBack = (i) => {
        if (origin == "external") {
            navigate("/shop");
        }
        else {
            navigate(-1);
        }
        setActiveGlobalTalent(i)
        
    }


    if (product) return (

        <div className={style.product}>

            <Slider className={style.product__img} {...settings}>
                {
                    product.img.map((img, index) => {
                        return (
                            <img className={style.product__img} src={img} alt="" key={index} loading="lazy" />
                        )
                    })
                }
            </Slider>

            <div className={style.product__info}>
                <h3 className={style.product__info__talent} > {product.talent_name}</h3>
                <h1>{product.name}</h1>
                <h3 className={style.product__info__price} >€{product?.price}</h3>

                <p className={style.product__info__desc} >{product.description}</p>
                <a className={style.product__info__btn} onClick={() => handleAddToCart(product)}> ADD to CART </a>
            </div>

            <Navigation />
            <div className={style.product__cartIcon} onClick={() => setOpenCartView(true)}>
                <img src={cartIcon} alt="open cart" />
            </div>

            <div className={style.product__backIcon} onClick={() => clickBack(product.talent)}>
                <img src={backIcon} alt="back button" draggable={false} />
            </div>
        </div>
    );
};
function camelCaseToKebabCase(str) {
    str = str.trim();
    str = str.replace(/\s+/g, '-');
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export default ProductDetail;