import { NavLink } from 'react-router-dom';
import style from './Newsletter.module.scss';
import Footer from '../Footer/Footer';

function Newsletter(){
    return(
        <div className={style.newsletter}>
            <h1>Iscriviti alla newsletter! </h1>
            <h3>Rimani sempre aggiornato sulle ultime novità di <strong>Rethinking Indigo.</strong> </h3>
            <div className={style.newsletter__input}>
                <input type="text" placeholder='Inserisci la tua email'/>
                <button>ISCRIVITI</button>
            </div>
            
            <Footer/>
        </div>
    )
}

export default Newsletter;