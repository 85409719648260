//import Navbar from "../../components/Navbar/Navbar.jsx";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";


function BaseLayout({children, username}){

    return(
        <>
            {/* <Navbar/> */}
            
                <div>
                    {children}
                </div>
            
            
            
        </>
    )
}

export default BaseLayout;