
//import Navbar from "../../components/Navbar/Navbar.jsx";
//import Header from "../../components/Header/Header.jsx";
import style from './Header.module.scss';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function Header({ }) {
    const location = useLocation();

  const navigate = useNavigate();

    return (
        <>
            <img onClick={() => navigate("/")} className={style.title} src={"./svg/logo.svg"} alt="" loading="lazy" />

        </>
    )
}

export default Header;