//import Navbar from "../../components/Navbar/Navbar.jsx";
//import Footer from "../../components/Footer/Footer.jsx";
import { NavLink } from 'react-router-dom';
import style from './Navigation.module.scss';

function Navigation(){
    return(
        <>
            <ul className={style.nav}>
                
                <NavLink to="/" className={({isActive}) =>  isActive ?  style.nav__item + " " + style.nav__active  :  style.nav__item }>about</NavLink>
                
                <NavLink to="/talents" className={({isActive}) =>  isActive ?  style.nav__item + " " + style.nav__active  :  style.nav__item }>talents</NavLink>
                
                <NavLink to="/journal" className={({isActive}) =>  isActive ?  style.nav__item + " " + style.nav__active  :  style.nav__item }>journal</NavLink>
                
                <NavLink to="/shop" className={({isActive}) =>  isActive ?  style.nav__item + " " + style.nav__active  :  style.nav__item }>shop</NavLink>
            
            </ul>
        </>
    )
}

export default Navigation;