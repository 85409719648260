import React from 'react';
import style from "./Product.module.scss"
import { useContext, useState, useEffect } from "react";
import { NavLink , useLocation} from 'react-router-dom';
import AuthContext from "../../utils/DataProvider.jsx";




const Product = ({product}) => {
    const { addItemCart, setOpenCartView } = useContext(AuthContext);
    

    const handleAddToCart = (obj) => {
        addItemCart(obj)
        setOpenCartView(true);
    }

    return (
        <>
            <div  className={style.product}>
                <NavLink to={`/product/${camelCaseToKebabCase(product.name)}`}  className={style.navlink} state={product}>
                    {/* <h3>{product?.price}</h3> */}
                    <div className={style.product__img}>
                        {product?.img[0] ?
                             <img className={style.product__img} src={product?.img[0] ? product?.img[0] : product.cover} draggable="false" alt="" />
                             : 
                             <div></div>
                        } 
                       
                    </div>
                    <h2>{product?.name}</h2>
                </NavLink>
                <p onClick={() => handleAddToCart(product)}> ADD to CART</p>
            </div>

           
        
        
        </>
        
    );
};

function camelCaseToKebabCase(str) {
    str = str.trim();
    str = str.replace(/\s+/g, '-');
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export default Product;