import React from 'react';
import style from './JournalDetail.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import { useLocation } from "react-router-dom";

import backIcon from '../../assets/img/arrow-down.svg';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import dataSost from '../../assets/data/heritage.json';
import dataJournal from '../../assets/data/journal.json';

const JournalDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [origin, setOrigin] = useState(null);




  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    const url = new URL(window.location.href);
    const pathname = url.pathname.replace(/\/$/, '');
    const lastPart = decodeURIComponent(pathname.split('/').pop());

    if (location.state) {
      setArticle(location.state);
    }
    else if (lastPart !== "journal") {
      let foundArticle = null;

      for (const item of dataSost.articles) {
        if (camelCaseToKebabCase(item.title) == lastPart) {
          foundArticle = item;
          break;
        }
      }

      if (!foundArticle) {
        for (const item of dataJournal.articles) {
          if (camelCaseToKebabCase(item.title) == lastPart) {
            foundArticle = item;
            break;
          }
        }
      }

      if (foundArticle) {
        setArticle(foundArticle);
        setOrigin("external")
        return;
      }


    }
    else {
      navigate("/");
    }
  }, []);


  const clickBack = (i) => {
    if (origin == "external") {
      navigate("/journal");
    }
    else {
      navigate(-1);
    }

  }



  if (article) return (

    <div className={style.article}>
      <h1 className={style.article__title}>{article.title}</h1>
      <h2 className={style.article__subtitle}>{article.introduction}</h2>
      {((article?.img != "/img/levi0.jpg") && article?.img) &&
        <div className={style.article__img} >
          <img src={article?.img} draggable="false" alt="" />
          <p className={style.article__imgDesc} >{article?.firstImgDesc ? article?.firstImgDesc : ""}</p>
        </div>

      }

      {article.content.map((content, index) => {
        return (
          <div className={style.article__section} key={index}>
            {content.img2 &&
              <div>
                <img className={style.article__img} src={content.img2} draggable="false" alt="" />
                <img className={style.article__img} src={content.img3} draggable="false" alt="" />

              </div>

            }
            {
              content.bigImg &&
              <div className={style.article__bigImg} >
                <img src={content.bigImg} draggable="false" alt="" />
                <p className={style.article__imgDescBig} >{content.imgDesc ? content.imgDesc : ""}</p>
              </div>

            }

            {content.img &&
              <div className={style.article__img} >
                <img src={content.img} draggable="false" alt="" />
                <p className={style.article__imgDesc} >{content.imgDesc ? content.imgDesc : ""}</p>
              </div>
            }



            <p className={style.article__section__question}  > {content.question}</p>
            <p className={style.article__section__answer} > <strong>{content.pre}</strong> {content.answer}</p>
          </div>
        )
      })
      }
      <div className={style.article__backIcon} onClick={() => clickBack()}>
        <img src={backIcon} alt="back button" draggable={false} />
      </div>
      <Navigation />

    </div>
  );
};


function camelCaseToKebabCase(str) {
  str = str.trim();
  str = str.replace(/\s+/g, '-');
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
export default JournalDetail;