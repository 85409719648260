import React, { useEffect, useState, useRef } from 'react';
import style from './About.module.scss';
import { NavLink } from 'react-router-dom';

import Footer from '../../components/Footer/Footer';
import Newsletter from '../../components/Newsletter/Newsletter';
import Dropdown from '../../components/Dropdown/Dropdown';


const About = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const textRef = useRef(null);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    const executeScroll = () => textRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    return (
        <div className={style.about}>
            <img className={style.about__title} src={"./svg/logo.svg"} alt="" />

            <ul className={style.about__nav}>

                <a onClick={() => executeScroll()} onMouseEnter={() => setHoveredIndex(1)} onMouseLeave={() => setHoveredIndex(null)} to="/" className={style.about__nav__item}>
                    {/* <p className={`${style.about__nav__num} ${hoveredIndex === 1 && style.about__nav__num__visible}`}>1</p> */}
                    <img className={`${style.about__nav__numImg} ${hoveredIndex === 1 && style.about__nav__numImg__visible}`} src={"./img/tasche/1.png"} alt="" />
                    <p>about</p>
                </a>


                <NavLink onMouseEnter={() => setHoveredIndex(2)} onMouseLeave={() => setHoveredIndex(null)} to="/talents" className={style.about__nav__item}>
                    {/* <p className={`${style.about__nav__num} ${hoveredIndex === 2 && style.about__nav__num__visible}`}>2</p> */}
                    <img className={`${style.about__nav__numImg} ${hoveredIndex === 2 && style.about__nav__numImg__visible}`} src={"./img/tasche/2.png"} alt="" />
                    <p>talents</p>
                </NavLink>


                <NavLink onMouseEnter={() => setHoveredIndex(3)} onMouseLeave={() => setHoveredIndex(null)} to="/journal" className={style.about__nav__item}>
                    {/* <p className={`${style.about__nav__num} ${hoveredIndex === 3 && style.about__nav__num__visible}`}>3</p> */}
                    <img className={`${style.about__nav__numImg} ${hoveredIndex === 3 && style.about__nav__numImg__visible}`} src={"./img/tasche/3.png"} alt="" />
                    <p>journal</p>
                </NavLink>


                <NavLink onMouseEnter={() => setHoveredIndex(4)} onMouseLeave={() => setHoveredIndex(null)} to="/shop" className={style.about__nav__item}>
                    {/* <p className={`${style.about__nav__num} ${hoveredIndex === 4 && style.about__nav__num__visible}`}>4</p> */}
                    <img className={`${style.about__nav__numImg} ${hoveredIndex === 4 && style.about__nav__numImg__visible}`} src={"./img/tasche/4.png"} alt="" />
                    <p>shop</p>
                </NavLink>

            </ul>


            <section className={style.about__section}>

                <p className={style.about__section__text} ref={textRef}>
                    Rethinking Indigo si inserisce nel contesto dell'industria del Denim, che si trova di fronte a una cruciale sfida ambientale. Tradizionalmente considerato un tessuto rivoluzionario per aver infranto gli schemi convenzionali, il Denim ha ormai consolidato la propria presenza nell'immaginario collettivo. Tuttavia, la sua rivoluzionaria natura può essere ridefinita attraverso un nuovo approccio all'utilizzo di questo materiale. <br /> <br /> Riteniamo che il Denim possa fungere da punto di partenza per un'innovazione significativa, partendo dal presupposto che già esiste. Se in passato l'innovazione richiedeva la creazione di qualcosa di completamente nuovo, oggi riteniamo che il processo di rivoluzione sia incentrato sulla reinvenzione delle risorse esistenti.
                </p>
                {/* <img className={style.about__section__img} src="/img/cane.jpg" alt="" /> */}





                {/* <img className={style.about__section__img} src="/img/cane.jpg" alt="" /> */}
                <p className={style.about__section__text}>
                    La piattaforma Rethinking Indigo si propone come un aggregatore di realtà italiane che operano nel settore del Denim, adottando un approccio innovativo e sostenibile. Il suo obiettivo primario è quello di fornire un luogo di incontro e collaborazione per le aziende che condividono questi valori, promuovendo nel contempo l'educazione e l'informazione riguardo all'impatto ambientale del Denim e alle sfide contemporanee ad esso correlate.
                    <br /> <br /> La piattaforma, oltre a promuovere l’operato dei designer, mira a sensibilizzare sulle questioni ambientali connesse alla produzione e all'uso del Denim, nonché a promuovere pratiche e soluzioni sostenibili nel settore. Questo approccio non solo vuole favorire una maggiore consapevolezza, ma vuole anche stimolare l'innovazione e l'adozione di pratiche più responsabili all'interno dell'industria del Denim italiana e oltre.                    </p>
            </section>

            <Newsletter />
            <Dropdown title="SERVIZI">
                <div className={style.about__services}>
                    <div>
                        <h3> INDIGO PREMIUM </h3>
                        <p>Questo pacchetto ricopre tutti i servizi  offerti da  Rethinking Indigo :</p>
                        <ul>
                            <li> <strong> Shop E-commerce:</strong> la possibilità di mettere in mostra i tuoi capi di Denim sostenibile con foto professionali e una vetrina dedicata.</li>
                            <li> <strong>Journal:</strong>   l’opportunità di raccontare la tua storia e la tua visione attraverso articoli o interviste esclusivi.</li>
                            <li> <strong>Editoriale:</strong>  possibilitá di ottenere scatti editoriali che raccontino l’essenza del tuo brand</li>
                            <li> <strong>Contenuti Social:</strong>  Miglioramento e potenziamento della tua presenza online attraverso contenuti personalizzati sui nostri account e ricondivisibili.</li>
                        </ul>
                    </div>

                    <div>
                        <h3> INDIGO STANDARD </h3>
                        <ul>
                            <li> <strong>Contenuti Social:</strong>   miglioramento e potenziamento della tua presenza online attraverso contenuti personalizzati sui nostri account e ricondivisibili.</li>
                            <li> <strong>Journal:</strong>   l’opportunità di raccontare la tua storia e la tua visione attraverso articoli o interviste esclusivi.</li>
                        </ul>
                    </div>


                    <div>
                        <h3> INDIGO REGULAR </h3>
                        <ul>
                            <li> <strong>Shop E-commerce:</strong>  la possibilità di mettere in mostra i tuoi capi di Denim sostenibile con foto professionali e una vetrina dedicata.</li>
                        </ul>
                    </div>
                </div>
            </Dropdown>
            <Dropdown title="CONTATTI">
                <div className={style.about__contacts}>
                    <div>
                        <img src="/svg/mail.svg" alt="" />
                        <a href='mailto:rethinking.indigo@gmail.com'>  rethinking.indigo@gmail.com</a>
                    </div>
                    <div>
                        <img src="/svg/phone.svg" alt="" />
                        <a href='tel:+39 3248639632'>+39 3248639632</a>
                    </div>
                    <div>
                        <img src="/svg/pin.svg" alt="" />
                        <a href='https://www.google.com/maps/place/IED+Istituto+Europeo+di+Design/@45.4458612,9.1938032,19z/data=!4m14!1m7!4m6!1m3!2m2!1d9.1950964!2d45.4458713!1m0!3e2!3m5!1s0x4786c410d636c0f7:0x5a3173b075efae34!8m2!3d45.4458655!4d9.1951591!16zL20vMGduNzBr?entry=ttu'>Sede : Via Pompeo Leoni 3, Milano.</a>
                    </div>
                   
                    
                    

                </div>
            </Dropdown>


        </div>
    );
};



export default About;