import { NavLink, useLocation } from "react-router-dom";
import style from "../Cart/Cart.module.scss"
import { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import closeIcon from "../../assets/img/close.svg"; 
import AuthContext from "../../utils/DataProvider.jsx";
import CartElement from "../CartElement/CartElement.jsx";

function Cart({ children, username }) {
    const { openCartView, setOpenCartView, getCart } = useContext(AuthContext);

    const { cart, setCart } = useContext(AuthContext);
    let sum = 0

    useEffect(() => {
        getCart()
    }, []);

    useEffect(() => {
        //console.log(openCartView);
    }, [openCartView]);

    return (
        <div className={style.cart}>
            <div className={openCartView ? style.overlayOpen : style.overlayClose} onClick={() => setOpenCartView(false)}></div>
            <div className={openCartView ? style.tabOpen : style.tabClose}>
                <div className={style.cart__topBar}>
                    <div className={style.cart__topBar__title}>
                        <h2><em>your </em> CART ({cart ? cart.length : 0})</h2>
                    </div>
                    <img  className={style.cart__topBar__ex} onClick={() => setOpenCartView(false)}   src={closeIcon} alt="" />
                   
                </div>
                <div className={style.cart__products}>
                    {cart && cart.map((item, key) => (
                        sum += parseFloat(item.price),
                        <CartElement key={key} obj={item} />
                    ))}



                </div>

                {sum !== 0 &&
                    <>
                        <div className={style.cart__total}>
                            <h2>TOTAL</h2>
                            <h2>€ {sum}</h2>
                        </div> 

                        <div className={style.cart__checkout} >
                            <h2>CHECKOUT</h2>
                        </div>
                    </>
                }

            </div>
        </div>

    )
}

export default Cart;