
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import talentsData from '../assets/data/talents.json';

const AuthContext = createContext();

export const AuthContextProvider= ({children}) => {

    const navigate = useNavigate();
    const [openCartView, setOpenCartView] = useState(false);
    const [talents, setTalents] =  useState(null);
    const [cart, setCart] =  useState(null);
    const [activeGlobalTalent, setActiveGlobalTalent] =  useState(null);

    useEffect(() => {
        setTalents(talentsData);
    });

    const getProducts = () => {
        
    };

    const getCart = () => {
        setCart(JSON.parse(localStorage.getItem("fashion_cart") || null));
    };

    const addItemCart = (obj) => {
        var newCart = [];
        if (cart && cart.some(item => item === obj)) return;
        if (cart) newCart = [...cart, obj];
        else newCart = [obj];
        
        setCart(newCart);
        localStorage.setItem("fashion_cart", JSON.stringify(newCart));
        
    };

    const deleteItemCart =  (obj) => {
        const newCart = cart.filter(item => item.name !== obj.name);
        setCart(newCart);
        localStorage.setItem("fashion_cart", JSON.stringify(newCart));
    };

    return(
        <>
           <AuthContext.Provider value={{ getProducts, getCart ,addItemCart, deleteItemCart, cart, setCart, openCartView , setOpenCartView, activeGlobalTalent ,setActiveGlobalTalent} }>
                {children}
           </AuthContext.Provider>
            
        </>
    )
}

export default AuthContext;


