import React from 'react';
import style from './Journal.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import dataJournal from '../../assets/data/heritage.json';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import BackButton from '../../components/BackButton /BackButton';


const JournalHertiage = () => {
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    setArticles(dataJournal.articles);
    window.scrollTo({ top: 0, left: 0 });

  }, []);



  return (
    <div className={style.journal}>
      <div className={style.journal__bar}></div>
      <h1>Journal</h1>
      <h2>HERITAGE</h2>
      <div className={style.journal__list}  >
        <BackButton />
        {articles && articles.map((article, key) => {
          return (
            <NavLink key={key}  to={`/journal/${camelCaseToKebabCase(article.title)}`} state={article}>

              
              <img className={style.journal__list__img} src={article.img ? article.img : article.cover} alt="" />
              <p key={key} > {article.title}</p>
            </NavLink>
          )
        })}
      </div>

      <Navigation />
   
    </div>
  );
};

function camelCaseToKebabCase(str) {
  str = str.trim();
  str = str.replace(/\s+/g, '-');
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export default JournalHertiage;