import { Route, Routes } from 'react-router-dom';
import './App.css';
import {AuthContextProvider} from "../src/utils/DataProvider.jsx"
import BaseLayout from './components/BaseLayout/BaseLayout';
import About from './pages/About/About';
import Journal from './pages/Journal/Journal';
import Shop from './pages/Shop/Shop';
import Talents from './pages/Talents/Talents';
import Cart from './components/Cart/Cart.jsx';
import ProductDetail from './pages/ProductDetail/ProductDetail.jsx';
import JournalDetail from './pages/JournalDetail/JournalDetail.jsx';
import JournalHertiage from './pages/Journal/JournalHeritage.jsx';
import JournalSustainability from './pages/Journal/JournalSustainability.jsx';


function App() {
  return (
    <AuthContextProvider>
      <BaseLayout>
        <Cart/>
        <Routes>
          <Route path="/" element={<About/>}/>
          <Route path="/talents" element={<Talents/>}/>
          <Route path="/journal" element={<Journal/>}/>
          <Route path="/journal/heritage" element={<JournalHertiage/>}/>
          <Route path="/journal/sustainability" element={<JournalSustainability/>}/>
          <Route path="/journal/:id" element={<JournalDetail/>} />
          <Route path="/shop" element={<Shop/>}/>
          <Route path="/product/:id" element={<ProductDetail/>} />
        </Routes>  
      </BaseLayout>
    </AuthContextProvider>
   
  );
}

export default App;
