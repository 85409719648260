//import Navbar from "../../components/Navbar/Navbar.jsx";
//import Footer from "../../components/Footer/Footer.jsx";
import style from './Footer.module.scss';
import { useLocation } from 'react-router-dom';

function Footer({ }) {
  const location = useLocation();
 



  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <>
 
        <div className={style.footer}>

          <img className={style.footer__social} onClick={() => openInNewTab("https://www.instagram.com/rethinking_indigo__/")} src={"./svg/instagram.svg"} alt="" />
          <img className={style.footer__social} onClick={() => openInNewTab("https://www.tiktok.com/@rethinking.indigo?_t=8nBtNocW3qS&_r=1")} src={"./svg/tiktok.svg"} alt="" />
          <img className={style.footer__social} onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=61560412026769")} src={"./svg/facebook.svg"} alt="" />
          <img className={style.footer__social} onClick={() => openInNewTab("https://www.linkedin.com/in/rethinking-indigo-5b4abb311/")} src={"./svg/linkedin.svg"} alt="" />


        </div>
      




    </>
  )
}

export default Footer;