import React from 'react';
import style from "./CartElement.module.scss"
import { useContext, useState, useEffect } from "react";

import AuthContext from "../../utils/DataProvider.jsx";

const CartElement = ({obj}) => {
    const { deleteItemCart } = useContext(AuthContext);
   
    return (
        <div className={style.cartElement}>
            <div className={style.cartElement__left}>
                <h2 className={style.cartElement__name}>{obj.name.toUpperCase()}</h2>
                <div className={style.cartElement__remove} onClick={() => deleteItemCart(obj)}>REMOVE</div>
            </div>
            
            <h3 className={style.cartElement__price}>€ {obj.price}</h3>
        </div>
    );
};

export default CartElement;